(function () {
  'use strict';

  angular
    .module('neo.home.postponements.postponedMatches')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.postponements.postponedMatches', {
        url: '/postponed-matches',
        templateUrl: 'home/postponements/postponed-matches/postponed-matches.tpl.html',
        controller: 'PostponedMatchesCtrl',
        controllerAs: 'vm'
      });
  }
}());
